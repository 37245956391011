// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
//
import Rails from '@rails/ujs';
import 'channels';
import 'controllers';
import 'bootstrap';
import "@fortawesome/fontawesome-free";
import "@fortawesome/fontawesome-free/css/all";
import '@oddcamp/cocoon-vanilla-js';
import '@yaireo/tagify/src/tagify.scss';


const jquery = require('jquery');
window.$ = window.jQuery = jquery;


// import 'feather-icons/dist/feather';

// import { icons } from 'feather-icons';
// import * as library from 'feather-icons';

// import moment from 'moment'
// window.moment = moment

import feather from 'feather-icons'
window.feather = feather

import 'bootstrap';
import './theme/simplebar.min';
import './theme/app';

// Custom theme elements
import '../styles/application.scss';

// Exyo page-specific logic
import './exercise_difficulty_sets';
import './mailbox';

const images = require.context('../images', true);

Rails.start();
